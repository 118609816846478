import { OutgoingLink } from '@47ng/chakra-next'
import { Button, Flex, FlexProps, Input } from '@chakra-ui/react'
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik'
import { EmailField } from 'modules/ui/forms'
import { useToast } from 'modules/ui/toasts/toast'
import { DimmedText } from 'modules/ui/typography/dimmedText'
import React from 'react'
import { FiMail } from 'react-icons/fi'

export interface NewsletterValues {
  email: string
  name: string
}

export interface NewletterFormProps extends FlexProps {
  alwaysStack?: boolean
  source: string
  metadata?: {
    [key: string]: string
  }
  buttonColor?: string
}

export const Newsletter: React.FC<NewletterFormProps> = ({
  source,
  metadata = {},
  alwaysStack = false,
  buttonColor = 'green',
  ...props
}) => {
  const mountTime = React.useRef(Date.now())
  const toast = useToast()

  const subscribeToNewsletter = React.useCallback(
    async function subscribeToNewsletter(
      values: NewsletterValues,
      helpers: FormikHelpers<NewsletterValues>
    ) {
      const send = values.name === '' && Date.now() - mountTime.current > 2000
      if (!send) {
        return
      }
      const body = new URLSearchParams()
      body.set('email', values.email)
      body.set('embed', '1')
      body.set('tag', source)
      for (const [metaKey, metaValue] of Object.entries(metadata)) {
        body.set(`metadata__${metaKey}`, metaValue)
      }
      await fetch(
        'https://buttondown.email/api/emails/embed-subscribe/chiffre.io',
        {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          body
        }
      )
      // if (Boolean(window.chiffre)) {
      //   window.chiffre.sendString({
      //     name: `landing:newsletter:email`,
      //     value: values.email
      //   })
      // }

      toast({
        status: 'success',
        title: 'Subscribed',
        description:
          'We will send you the last edition of our newsletter, check your inbox!'
      })
      helpers.resetForm()
    },
    [source, metadata, toast]
  )

  const layout = (options: any[]) => (alwaysStack ? options[0] : options)

  const initialValues: NewsletterValues = {
    email: '',
    name: ''
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={subscribeToNewsletter}
      validate={values => {
        const errors: FormikErrors<NewsletterValues> = {}
        if (!values.email) {
          errors.email = 'Email address is required'
        } else if (!/^[\w.%+-]+@[\w.-]+\.[a-z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address'
        }
        return errors
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Flex
            justifyContent="center"
            wrap={layout(['wrap', 'nowrap'])}
            {...props}
          >
            <EmailField
              placeholder="enter your email address"
              w="100%"
              mr={layout([0, 2])}
              mb={0}
              bg="card.bg"
            />
            <Input
              // Honeypot for robots (humans can't fill this field)
              name="name"
              display="none"
              aria-hidden="true"
              placeholder="enter your name"
            />
            <Button
              isLoading={isSubmitting}
              loadingText="Send me updates"
              type="submit"
              colorScheme={buttonColor}
              leftIcon={<FiMail />}
              mt={layout([2, 0])}
              w={layout(['100%', 'auto'])}
              flexShrink={0}
            >
              Send me updates
            </Button>
          </Flex>
          <DimmedText fontSize="xs" mt={1}>
            We won't send you spam, unsubscribe at any time. Newsletter operated
            by{' '}
            <OutgoingLink
              href="https://buttondown.email/chiffre.io"
              textDecoration="underline"
            >
              Buttondown
            </OutgoingLink>
            .
          </DimmedText>
        </Form>
      )}
    </Formik>
  )
}
