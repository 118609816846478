import {
  Box,
  BoxProps,
  CloseButton,
  Flex,
  RenderProps,
  Text,
  useColorModeValue,
  useToast as useChakraToast,
  UseToastOptions
} from '@chakra-ui/react'
import { StatusIcons } from 'modules/ui/icons'
import { DimmedText } from 'modules/ui/typography/dimmedText'
import React from 'react'
import { IconType } from 'react-icons'

export interface ToastData
  extends Pick<UseToastOptions, 'title' | 'description' | 'status'> {
  icon?: IconType
}

export interface ToastProps
  extends RenderProps,
    ToastData,
    Omit<BoxProps, 'title' | 'id'> {
  actionButton?: React.ReactNode
}

export const Toast: React.FC<ToastProps> = ({
  onClose,
  title,
  description,
  status,
  children,
  id,
  icon,
  actionButton,
  ...props
}) => {
  const statusIcon = status && { ...StatusIcons[status] }
  const statusColor = useColorModeValue(
    `${statusIcon?.color ?? 'gray'}.500`,
    `${statusIcon?.color ?? 'gray'}.300`
  )
  const _icon = icon ?? statusIcon?.icon

  return (
    <Box
      px={4}
      py={3}
      bg={useColorModeValue('white', 'gray.700')}
      shadow="md"
      maxW="lg"
      rounded="md"
      borderLeftWidth={'6px'}
      borderLeftColor={statusColor}
      {...props}
    >
      <Flex alignItems="baseline">
        {_icon && (
          <Box
            as={_icon}
            display="inline-block"
            flexShrink={0}
            mr={2}
            transform="translateY(0.125rem)"
            color={statusColor}
            role="img"
            aria-label={status}
          />
        )}
        <Text
          fontWeight="semibold"
          lineHeight="short"
          mr={2} // to avoid clashing with the close icon
        >
          {title}
        </Text>
        {actionButton}
        <CloseButton
          aria-label="Dismiss"
          onClick={onClose}
          size="sm"
          rounded="full"
          ml={actionButton ? 2 : 'auto'}
          mr={-1}
        />
      </Flex>
      {description && <DimmedText mt={1}>{description}</DimmedText>}
      {children}
    </Box>
  )
}

// --

export interface UseCustomToastOptions extends UseToastOptions {
  icon?: IconType
  props?: Partial<ToastProps>
}

export const defaultToastOptions: UseCustomToastOptions = {
  position: 'bottom-right'
}

export function useToast(
  setupOptions: UseCustomToastOptions = defaultToastOptions
) {
  const toast = useChakraToast(setupOptions)
  const open = React.useCallback(
    (callOptions: UseCustomToastOptions = {}) => {
      const runtimeOptions = Object.assign(
        {},
        defaultToastOptions,
        setupOptions,
        callOptions,
        {
          render: (props: RenderProps) => (
            <Toast
              title={runtimeOptions.title}
              description={runtimeOptions.description}
              status={runtimeOptions.status}
              icon={runtimeOptions.icon}
              {...runtimeOptions.props}
              {...props}
            />
          )
        }
      )
      return toast(runtimeOptions)
    },
    [toast, setupOptions]
  )
  return open
}
