import type { AlertStatus } from '@chakra-ui/react'
import type { IconType } from 'react-icons'
import {
  FiAlertCircle,
  FiAlertTriangle,
  FiCheckCircle,
  FiInfo
} from 'react-icons/fi'

export const StatusIcons: {
  [key in AlertStatus]: { icon: IconType; color: string }
} = {
  info: { icon: FiInfo, color: 'blue' },
  error: { icon: FiAlertCircle, color: 'red' },
  warning: { icon: FiAlertTriangle, color: 'yellow' },
  success: { icon: FiCheckCircle, color: 'green' }
} as const
