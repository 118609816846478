import { forwardRef, Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export interface DimmedTextProps extends TextProps {}

export const DimmedText = forwardRef<DimmedTextProps, 'p'>(
  ({ ...props }, ref) => {
    return <Text fontSize="sm" color="gray.500" ref={ref} {...props} />
  }
)
